.GalleryScrollTwo-main-container {
    height: fit-content;
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #ffffff;
    /* margin-top: 8vh; */
    /* gap: 2vh; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
}

.tiles {
    --tiles-height: 52vw;
    height: var(--tiles-height);
    position: relative;
    overflow: hidden;
}

.tiles--columns {
    --tiles-height: 200vh;
}

.tiles__wrap {
    width: 150%;
    --tileswrap-height: var(--tiles-height);
    height: var(--tileswrap-height);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}


.tiles--columns .tiles__wrap {
    width: 100%;
    display: flex;
    --tileswrap-height: calc(var(--tiles-height) * 1.4);
    transform: translate3d(0, -50%, 0);
    left: 0;
    top: 50%;
}

.tiles__line {
    display: flex;
}

.tiles--columns .tiles__line {
    width: 25%;
    padding: 0 1rem;
    display: block;
}

.tiles__line-img {
    --tile-margin: 2vw;
    flex: none;
    width: calc(16.6666% - var(--tile-margin) * 2);
    height: calc(var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
    margin: var(--tile-margin);
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.Author_Text{
  box-shadow: inset 0 0 0 2000px rgba(12, 12, 12, 0.801);
  padding: 2px;
  color: #ffffff;
  font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 2vw;
}

.tiles--columns .tiles__line-img {
    --tile-margin: 2rem 0;
    width: 100%;
    height: calc(25vw * 1.3);
}

@media only screen and (max-device-width: 810px) {
    .GalleryScrollTwo-main-container{
      display: none;
    }
  }

@media screen and (min-width: 53em) {

    .tiles--columns-rotated .tiles__line {
        width: 33vmax;
    }

    .tiles--columns-rotated .tiles__line:nth-child(even) {
        margin-top: -20vmax;
    }

    .tiles--columns-rotated .tiles__line-img {
        height: 40vmax;
    }
}