.gallery-main-container {
  height: auto;
  width: 80%;
  /* background: rgb(161, 161, 161); */
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.gallery-section-1-container {
  /* height: 60vh; */
  width: 100%;
  /* background: rgb(158, 19, 19); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0 20px 0;
}

.gallery-section-1-left-subheading {
  width: 100%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--color-2);
}

.gallery-section-1-left {
  /* height: 30vh; */
  width: 100%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--font-color-1);
  /* background: #000; */
}

.gallery-section-1-left-description {
  /* height: 30vh; */
  width: 100%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--font-color-1);
}

.gallery-section-2-container {
  height: fit-content;
  width: 100%;
  /* background: rgb(19, 98, 158); */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.gallery-image {
  cursor: pointer;
  transition: transform 0.2s;
}

/* .gallery-image:hover {
      transform: scale(1.2);
    } */

@media only screen and (max-device-width: 810px) {
  .gallery-section-1-container {
    flex-direction: column;
    gap: 2vh;
  }

  .gallery-section-1-left,
  .gallery-section-1-right {
    margin: 0;
    height: auto;
    width: 90%;
    align-items: center;
    justify-content: center;
  }

  .gallery-main-container {
    margin-top: 5vh;
  }

  .gallery-section-1-left-subheading {
    align-items: center;
    justify-content: center;
  }
}