.contact-page-main-container {
  height: fit-content;
  width: 100%;
  /* margin-top: 8vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1vh;
  background: var(--bg-color);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3vw;
  margin-top: 8vh;
}

.contact-page-map-container {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3vh;
}

.contact-page-map-title {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-heading-2);
  color: var(--secondary-font-color-1);
}

.contact-page-map-description {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  text-align: justify;
  font-size: var(--font-size-3);
  color: var(--secondary-font-color-1);
}

.contact-page-map-image {
  height: 80vh;
  width: 100%;
}

.map-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.contact-page-contact-container {
  height: fit-content;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.office-container {
  height: fit-content;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom:5vh;
  margin-top:5vh;
}

.contact-title {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-2);
  color: var(--secondary-font-color-1);
}

.contact-content{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  text-align: justify;
  font-size: var(--font-size-3);
  color: var(--secondary-font-color-1);
}

@media only screen and (max-device-width: 810px) {
  .office-container {
    width: 70%;
  }

  .contact-content{
    text-align: center;
  }
}