.landing-main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background: #416b43; */
    /* margin-top: 8vh; */
    /* gap: 2vh; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
    padding-top: 4vh;
}

.landing-left-container {
    height: 65%;
    width: 40%;
    /* background: #000000; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 5%;
    gap: 5%;
}

.title-container {
    height: fit-content;
    width: 80%;
    /* background: #da7575; */
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-heading-2);
    color: var(--primary-font-color-2);
    text-align: left;
}

.landing-image-container {
    height: fit-content;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-image-container>img {
    width: 80%;
}

.event-name-color {
    color: var(--color-1);
}

.description-container {
    height: fit-content;
    width: 80%;
    /* background: #d13a3a; */
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    text-align: justify;
    font-size: var(--font-size-3);
    color: var(--primary-font-color-2);
}

.button-container {
    height: 20%;
    width: 80%;
    /* background: #118057; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10%;
}

.landing-right-container {
    height: 80%;
    width: 60%;
    /* background: #bf6969; */
    display: flex;
    align-items: center;
    justify-content: center;

}

.button-row {
    display: flex;
    flex-direction: row;
    gap: 5%;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 80%;
}

a {
    align-self: center;
    justify-self: center;
    padding: 0%;
    width: 40%;
}


@media only screen and (max-device-width: 810px) {

    .landing-main-container {
        height: 100vh;
        flex-direction: column;
        /* gap: 1; */
        padding-top: 40%;
    }

    .landing-left-container {
        width: 100%;
        margin-left: 0%;
    }

    .landing-right-container {
        width: 100%;
        align-items: flex-start;
        /* background: #bf6969; */
    }

    .landing-image-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-container {
        align-self: center;
        scale: 90%;
    }

    .button-row {
        /* flex-direction: column; */
        gap: 1%;
    }

    .logo {
        width: 70%;
    }
}