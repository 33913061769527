.team-page-main-container {
  height: fit-content;
  width: 100%;
  /* margin-top: 8vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1vh;
  background: var(--bg-color);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3vw;
}

.team-page-member-container {
  height: fit-content;
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10%;
  padding: 10vh 0 10vh 0;
}



@media only screen and (max-device-width: 810px) {
  .team-page-contact-container{
    align-items: center;
    flex-direction: column;
  }
}