.teamcard-main-container{
    height: fit-content;
    width: 20%;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1vh;
    margin: 5vh;
}


.team-image-container{
    width: 100%;
    object-fit:cover;
}


.team-heading-container{
    height: fit-content;
    width: 90%;
    /* background: #ac2424; */
    display: flex;
    align-items: center;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    text-align: justify;
    font-size: var(--font-size-2);
    color: var(--secondary-font-color-1);
}

.team-description-container{
    height: fit-content;
    width: 90%;
    /* background: #ac2424; */
    display: flex;
    align-items: center;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    text-align: justify;
    font-size: var(--font-size-4);
    color: var(--secondary-font-color-1);
}

@media only screen and (max-device-width: 810px) {
    .teamcard-main-container{
        width: 60%;
    }

    .team-description-container{
        text-align: center;
    }
}