.GalleryScrollOne-main-container {
  height: fit-content;
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  /* margin-top: 8vh; */
  /* gap: 2vh; */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3vw;
}


.trail {
  height: 1000px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  isolation: isolate;
}

.trail__img {
  max-width: 350px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  will-change: transform;
  filter: none;
}

.trail__title {
  font-family: forma-djr-display, sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 10000;
  font-size: 27vw;
  mix-blend-mode: difference;
  -webkit-text-stroke: 2px #000000;
  text-stroke: 2px #000000;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  color: transparent;
  pointer-events: none;
}


.tiles {
  --tiles-height: 52vw;
  height: var(--tiles-height);
  position: relative;
  overflow: hidden;
}

.tiles--columns {
  --tiles-height: 140vh;
}

.tiles__wrap {
  width: 150%;
  --tileswrap-height: var(--tiles-height);
  height: var(--tileswrap-height);
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.tiles--rotated .tiles__wrap {
  --tileswrap-height: calc(var(--tiles-height) * 1.8);
  transform: translate3d(-50%, -50%, 0) rotate(22.5deg);
}

.tiles--columns .tiles__wrap {
  width: 100%;
  display: flex;
  --tileswrap-height: calc(var(--tiles-height) * 1.4);
  transform: translate3d(0, -50%, 0);
  left: 0;
  top: 50%;
}

.tiles__line {
  display: flex;
}

.tiles--columns .tiles__line {
  width: 25%;
  padding: 0 1rem;
  display: block;
}

.tiles__line-img {
  --tile-margin: 2vw;
  flex: none;
  width: calc(16.6666% - var(--tile-margin) * 2);
  height: calc(var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
  margin: var(--tile-margin);
  background-size: cover;
  background-position: 50% 50%;
}

.tiles--rotated .tiles__line-img {
  --tile-margin: 1vw;
  width: calc(16.6666% - var(--tile-margin) * 2);
  height: calc(var(--tileswrap-height) / 5 - (4 * var(--tile-margin) / 2));
}

@media only screen and (max-device-width: 810px) {
  .GalleryScrollOne-main-container{
    display: none;
  }
}

@media screen and (min-width: 53em) {

  .tiles--columns-rotated .tiles__line {
    width: 33vmax;
  }

  .tiles--columns-rotated .tiles__line:nth-child(even) {
    margin-top: -20vmax;
  }

  .tiles--columns-rotated .tiles__line-img {
    height: 40vmax;
  }
}