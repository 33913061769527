.InNumbers-main-container{
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* background: #785360; */
    /* margin-top: 8vh; */
    /* gap: 2vh; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
    /* gap: 10%; */
}

@media only screen and (max-device-width: 810px) {
    .InNumbers-main-container{
        height: fit-content;
        gap: 2vh;
    }
}