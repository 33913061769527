.trail-main-container {
    height: fit-content;
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #ffffff;
    /* margin-top: 8vh; */
    /* gap: 2vh; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
}

img {
    border: 0;
}

.trail {
    height: 1000px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    isolation: isolate;
}

.trail__img {
    max-width: 350px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    will-change: transform;
    filter: none;
}

.trail__title {
    font-family: forma-djr-display, sans-serif;
    font-weight: 700;
    position: relative;
    z-index: 1000;
    font-size: 27vw;
    mix-blend-mode: difference;
    -webkit-text-stroke: 2px #000000;
    /* text-stroke: 2px #000000; */
    -webkit-text-fill-color: transparent;
    /* text-fill-color: transparent; */
    color: transparent;
    pointer-events: none;
}



@media only screen and (max-device-width: 810px) {
    .trail-main-container {
        display: none;
    }
}